
import ThridPage from './thridPage.vue';
import FirstPage from './firstPage.vue';
import SecondPage from './secondPage.vue';
import pageFour from './pageFour.vue';
import pageFive from './pageFive.vue';
import pageSix from './pageSix.vue';
import endPage from './endPage.vue';
import ai from './ai.vue';
import 'animate.css';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
// import type {  } from 'swiper/swiper.d.ts';
import SwiperCore, { Navigation, Mousewheel, A11y, Pagination } from 'swiper';
import { getPicture } from '../../services';

SwiperCore.use([Navigation, Mousewheel, A11y, Pagination]);
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue';
import { Swiper as SwiperEvents } from 'swiper/types';
import bus from 'vue3-eventbus';
import FooterC from '../../components/footer.vue';
export default defineComponent({
  components: {
    ThridPage,
    FirstPage,
    SecondPage,
    pageFour,
    pageSix,
    endPage,
    Swiper,
    pageFive,
    SwiperSlide,
    FooterC,
    ai
  },
  setup() {
    // 将swiper实例保存
    let swiperInstance: any;
    let data = reactive({
      swiperIndex: 0
    });

    //对应变色的导航
    let activeA = ref(1);
    const onSwiper = (swiper?: any) => {
      swiper.slideTo(0);
      swiperInstance = swiper;
    };
    const onActiveIndexChange = (swiper: SwiperEvents) => {
      data.swiperIndex = swiper.activeIndex;
    };

    //点击导航栏变色
    const activeNav = (index: number) => {
      activeA.value = index;
    };
    onMounted(async () => {
      onActiveIndexChange(swiperInstance);
      const pictureList = await getPicture();
      bus.emit('handleImg', pictureList);
    });
    return {
      onSwiper,
      ...toRefs(data),
      activeA,
      onActiveIndexChange,
      activeNav
    };
  }
});
