
import { defineComponent, reactive, watch, toRefs } from 'vue';

export default defineComponent({
  props: {
    swiperIndex: {
      type: Number,
      default: 5
    }
  },
  setup(props) {
    const data = reactive({
      nowPage: 5
    });
    watch(
      () => props.swiperIndex,
      (newVla) => {
        data.nowPage = Number(newVla);
      }
    );
    return {
      ...toRefs(data)
    };
  }
});
