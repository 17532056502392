
import { defineComponent, watch, reactive, toRefs, onMounted, nextTick } from 'vue';
import 'animate.css';
import SVGA from 'svgaplayerweb';

export default defineComponent({
  props: {
    swiperIndex: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const data = reactive({
      nowPage: 0
    });
    onMounted(() => {
      // nextTick(() => {
      //   const player = new SVGA.Player(`#fireCanvas`);
      //   const parser = new SVGA.Parser();
      //   parser.load(
      //     'https://cdnsource.bugegaming.com/buge-pc/images/svg/firework.svga',
      //     (videoItem) => {
      //       player.setVideoItem(videoItem);
      //       player.startAnimation();
      //     }
      //   );
      // });
    }),
      watch(
        () => props.swiperIndex,
        (newVla) => {
          data.nowPage = Number(newVla);
        }
      );
    return {
      ...toRefs(data)
    };
  }
});
