import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_first_page = _resolveComponent("first-page")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_second_page = _resolveComponent("second-page")!
  const _component_page_four = _resolveComponent("page-four")!
  const _component_ai = _resolveComponent("ai")!
  const _component_thrid_page = _resolveComponent("thrid-page")!
  const _component_page_five = _resolveComponent("page-five")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      direction: 'vertical',
      slidesPerView: 1,
      spaceBetween: 0,
      mousewheel: { releaseOnEdges: true },
      mousewheelControl: true,
      autoHeight: true,
      resistanceRatio: 0,
      observer: true,
      observeParents: true,
      allowTouchMove: true,
      activeSlide: 0,
      pagination: {
        clickable: true
      },
      onSwiper: _ctx.onSwiper,
      onActiveIndexChange: _ctx.onActiveIndexChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_swiper_slide, null, {
          default: _withCtx(() => [
            _createVNode(_component_first_page, { swiperIndex: _ctx.swiperIndex }, null, 8, ["swiperIndex"])
          ]),
          _: 1
        }),
        _createVNode(_component_swiper_slide, null, {
          default: _withCtx(() => [
            _createVNode(_component_second_page, { swiperIndex: _ctx.swiperIndex }, null, 8, ["swiperIndex"])
          ]),
          _: 1
        }),
        _createVNode(_component_swiper_slide, null, {
          default: _withCtx(() => [
            _createVNode(_component_page_four, { swiperIndex: _ctx.swiperIndex }, null, 8, ["swiperIndex"])
          ]),
          _: 1
        }),
        _createVNode(_component_swiper_slide, null, {
          default: _withCtx(() => [
            _createVNode(_component_ai, { swiperIndex: _ctx.swiperIndex }, null, 8, ["swiperIndex"])
          ]),
          _: 1
        }),
        _createVNode(_component_swiper_slide, null, {
          default: _withCtx(() => [
            _createVNode(_component_thrid_page, { swiperIndex: _ctx.swiperIndex }, null, 8, ["swiperIndex"])
          ]),
          _: 1
        }),
        _createVNode(_component_swiper_slide, {
          class: _normalizeClass('last')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_page_five, { swiperIndex: _ctx.swiperIndex }, null, 8, ["swiperIndex"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onSwiper", "onActiveIndexChange"])
  ]))
}