
import { defineComponent, ref, watch, reactive, toRefs, onMounted } from 'vue';
import downloadBtn from '../../components/downloadBtn.vue';
import 'animate.css';

export default defineComponent({
  components: { downloadBtn },
  props: {
    swiperIndex: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const isAppshow = ref(false);
    const isAndShow = ref(false);
    const data = reactive({
      nowPage: 0
    });
    watch(
      () => props.swiperIndex,
      (newVla) => {
        data.nowPage = Number(newVla);
      }
    );
    //展示二维码
    const showEwm = (e: number) => {
      if (e == 1) {
        isAppshow.value = true;
      } else if (e == 2) {
        isAndShow.value = true;
      }
    };
    //关闭二维码
    const closeEwm = (e: number) => {
      if (e == 1) {
        isAppshow.value = false;
      } else if (e == 2) {
        isAndShow.value = false;
      }
    };
    onMounted(() => {
      data.nowPage = 0;
    });
    return {
      // slideToMainPage,
      // playGameRef
      ...toRefs(data),
      showEwm,
      closeEwm,
      isAndShow,
      isAppshow
    };
  }
});
