import axios from 'axios';

// 前置拦截器（发起请求之前的拦截）
axios.interceptors.request.use(
  (config) => {
    /**
     * 根据你的项目实际情况来对 config 做处理
     * 这里对 config 不做任何处理，直接返回
     */
    return config;
  },
  (error) => {
    return error;
  }
);

export const getPicture = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('https://cms.gxxwan.com/mozai-cms/app/download/config', {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
