
import { defineComponent, reactive, watch, toRefs } from 'vue';
import FooterC from '../../components/footer.vue';
export default defineComponent({
  components: {
    FooterC
  },
  props: {
    swiperIndex: {
      type: Number,
      default: 3
    }
  },
  setup(props) {
    const data = reactive({
      nowPage: 3
    });
    watch(
      () => props.swiperIndex,
      (newVla) => {
        data.nowPage = Number(newVla);
      }
    );
    return {
      ...toRefs(data)
    };
  }
});
